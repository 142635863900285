import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';

// markup
const NotFoundPage = () => (
  <Layout>
    <SEO title="Oops.. not found" />

    <div className="not-found">
      <div className="wrapper">
        {/* Hero */}
        <Hero
          h1="Not Found"
          p="Sorry, we couldn’t find what you were looking for."
        />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
